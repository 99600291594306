<script setup lang="ts">
const route = useRoute()

const { $getFilterOptions } = useNuxtApp()
const { locale, t, setLocale } = useI18n()
const localePath = useLocalePath()

const options = computed(() => [
  { value: localePath('/analysis/products'), label: t('找商品') },
  { value: localePath('/analysis/shop'), label: t('找小店') },
  { value: localePath('/analysis/blogger'), label: t('找达人') },
  { value: localePath('/analysis/video'), label: t('找视频') },
  { value: localePath('/analysis/live'), label: t('找直播') },
])
const curLocaleOpt = computed(() => $getFilterOptions().locales.find(({ value }) => locale.value === value))

const showSearch = computed(() => {
  return options.value.find(({ value }) => route.path === value)
})

function onChange(path: string) {
  navigateTo(path)
}
</script>

<template>
  <a-layout class="bg-[--color-fill-3]">
    <a-layout-header class="h-70 flex bg-black px-20">
      <!-- left -->
      <a-space class="h-full" :size="24">
        <NuxtLink :to="localePath('/')">
          <g-svg class="w-111 text-white" name="glodastory-logo" :width="111" :height="32" />
        </NuxtLink>
        <LayoutSearch />
      </a-space>
      <div class="h-full flex-1" />
      <!-- right -->
      <a-space :size="20">
        <LayoutContactTrigger v-if="locale === 'zh'" title="小程序" dec="扫码使用微信小程序" icon="icon-xianxingxiaochengxu" />
        <LayoutContactTrigger />
        <!-- <a-link class="!bg-transparent !text-white">
            <i class="icon-xianxinghuiyuangoumai mr-4" />
            {{ $t('领会员') }}
          </a-link> -->
        <g-dropdown-radio
          :model-value="locale"
          :label="curLocaleOpt?.label"
          :label-style="{ color: '#fff' }"
          :show-all="false"
          :options="$getFilterOptions().locales"
          @change="setLocale"
        />
        <LayoutUserAvatar />
      </a-space>
    </a-layout-header>
    <a-layout-content class="flex">
      <LayoutMenu />
      <div
        id="g-content-wrapper"
        class="box-border h-[calc(100vh-70px)] flex-1 overflow-auto p-20"
      >
        <g-card v-if="showSearch" class="rd-b-0 pb-0">
          <a-space :size="16" fill class="justify-center">
            <a-button
              v-for="opt in options" :key="opt.value"
              type="primary" shape="round" size="large"
              class="w-90 font-500"
              :class="{ 'hover:text-[--color-brand-6]!': opt.value !== route.path }"
              :style="opt.value === route.path ? { backgroundColor: 'var(--color-brand-6)' } : { color: 'var(--color-text-1)', background: 'transparent' }"
              @click="onChange(opt.value)"
            >
              {{ opt.label }}
            </a-button>
          </a-space>
          <LayoutAnalysisSearch class="mx-auto mt-30" />
        </g-card>
        <slot />
      </div>
    </a-layout-content>
  </a-layout>
</template>

<style scoped lang="less">
</style>
