import { Modal } from '@arco-design/web-vue'

import SearchTranslate from '~/components/dialog/SearchTranslate.vue'

export function useSearchTranslate() {
  const { t } = useI18n()

  let isOk = false

  return {
    open: (params?: { text?: string }) => {
      return new Promise<string>((resolve) => {
        Modal.open({
          title: t('翻译对照'),
          width: 840,
          content: () => h(
            SearchTranslate,
            {
              text: params?.text,
              onClose: (text) => {
                if (isOk) {
                  resolve(text)
                }
              },
            },
          ),
          onOk: () => {
            isOk = true
          },
          onCancel: () => {
            isOk = false
          },
        })
      })
    },
  }
}
