<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query'

const props = withDefaults(defineProps<{
  text?: string
}>(), {})
const emit = defineEmits<{
  close: [value: string]
}>()

const unTranslateText = ref(props.text ?? '')

const languageOptions = [
  {
    value: 'zh-CN',
    label: '中文',
  },
  {
    value: 'en',
    label: '英文',
  },
  {
    value: 'vi',
    label: '越南语',
  },
  {
    value: 'th',
    label: '泰语',
  },
  {
    value: 'id',
    label: '印度尼西亚语',
  },
]
const sourceLang = ref('zh-CN')
const targetLang = ref('en')
const { $request } = useNuxtApp()
const { apiV2 } = useRuntimeConfig().public
const { data: translateText, isFetching: loading } = useQuery({
  queryKey: ['/translation', unTranslateText, targetLang],
  queryFn: async ({ signal }) => {
    if (!unTranslateText.value)
      return ''
    const res = await $request<string>('/translation', {
      method: 'POST',
      baseURL: apiV2,
      body: {
        text: unTranslateText.value,
        targetLang: targetLang.value,
        sourceLang: sourceLang.value || 'en',
      },
      signal,
    })
    return res
  },
})

onBeforeUnmount(() => {
  emit('close', translateText.value ?? '')
})
</script>

<template>
  <div>
    <div class="flex items-center justify-end pb-16">
      <a-select v-model="sourceLang" class="w-fit rounded-50" :trigger-props="{ contentClass: 'min-w-120' }">
        <a-option v-for="item in languageOptions" :key="item.value" :label="$t(item.label)" :value="item.value" />
      </a-select>
    </div>
    <a-textarea v-model="unTranslateText" class="h-96 rounded-8 bg-[--color-fill-3] py-4" />

    <div class="flex items-center justify-end py-16">
      <a-select v-model="targetLang" class="w-fit rounded-50" :trigger-props="{ contentClass: 'min-w-120' }">
        <a-option v-for="item in languageOptions" :key="item.value" :label="$t(item.label)" :value="item.value" />
      </a-select>
    </div>
    <a-spin :loading="loading" class="w-full">
      <div class="relative">
        <a-textarea :model-value="translateText" class="h-96 rounded-8 bg-[--color-fill-3] py-4" />
        <div class="absolute bottom-8 right-12">
          <btns-copy :text="translateText ?? ''" />
        </div>
      </div>
    </a-spin>
  </div>
</template>
