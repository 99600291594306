<script setup lang="ts">
const searchType = ref('products')
const keyword = ref('')

const placeholder = computed(() => {
  const map: Record<string, string> = {
    products: '商品',
    shop: '小店',
    blogger: '达人',
    video: '视频',
    live: '直播',
  }

  return `请输入${map[searchType.value]}标题`
})

function onSearch() {
  navigateTo(`/analysis/${searchType.value}${keyword.value ? `?keyword=${keyword.value}` : ''}`)
}

const { open: translate } = useSearchTranslate()
function handleTranslate() {
  translate({ text: keyword.value }).then((text) => {
    keyword.value = text
  })
}
</script>

<template>
  <div class="h-38 w-360">
    <a-input
      v-model="keyword"
      class="h-full overflow-hidden rounded-24 border-none px-0 text-[--color-text-4] !bg-[--color-text-1] focus:text-white"
      :placeholder="$t(placeholder)" allow-clear
      @change="onSearch"
    >
      <template #prefix>
        <div class="h-full min-w-74">
          <a-select
            v-model="searchType"
            class="h-full rounded-none border-none pl-18 pr-0 text-[--color-fill-3] !bg-[--color-text-1] hover:bg-[--color-text-1]"
            default-active-first-option
          >
            <a-option value="products">
              {{ $t('商品') }}
            </a-option>
            <a-option value="shop">
              {{ $t('小店') }}
            </a-option>
            <a-option value="blogger">
              {{ $t('达人') }}
            </a-option>
            <a-option value="video">
              {{ $t('视频') }}
            </a-option>
            <a-option value="live">
              {{ $t('直播') }}
            </a-option>
          </a-select>
        </div>
      </template>
      <template #suffix>
        <i class="icon-zhongyingwenqiehuan cursor-pointer text-18 text-[--color-text-3]" @click="handleTranslate" />
        <a-button type="primary" class="ml-12 rounded-none !h-full !w-52" @click="onSearch">
          <template #icon>
            <icon-search class="text-16" />
          </template>
        </a-button>
      </template>
    </a-input>
  </div>
</template>

<style scoped lang="less">
:deep(.arco-select-view-single .arco-select-view-suffix) {
  @apply "pl-4 text-[--color-text-4]";
}
:deep(.arco-select-view-single .arco-select-view-value) {
  @apply "font-500";
}
:deep(.arco-input-wrapper .arco-input-clear-btn), :deep(.arco-input-focus) {
  @apply "text-white";
}
:deep(.arco-input-wrapper:not(.arco-input-focus) .arco-input-icon-hover:hover::before), :deep(.arco-icon-hover:hover::before) {
  @apply "bg-transparent";
}
</style>
