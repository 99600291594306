<script setup lang="ts">
import contact from '~/assets/images/index/客服号.png'
import weChat from '~/assets/images/index/小程序.png'
import email from '~/assets/images/user/email.png'

const props = withDefaults(
  defineProps<{
    title?: string
    dec?: string
    icon?: string
  }>(),
  {
    title: '联系客服',
    dec: '扫码关注专属客服',
    icon: 'icon-xianxinglianxikefu',
  },
)
const { locale } = useI18n()
const isWeChat = computed(() => props.title === '小程序')
</script>

<template>
  <a-trigger
    auto-fit-position
    :popup-translate="[0, 12]"
    position="br"
  >
    <a-link class="!bg-transparent !text-white">
      <i :class="`${icon} mr-4`" />
      {{ $t(title) }}
    </a-link>
    <template #content>
      <div class="box-border w-268 rd-8 bg-white p-24 text-center text-16 text-[--color-text-1] font-500 shadow-[0_4px_16px_rgba(0,0,0,0.08)]">
        <template v-if="locale === 'en' && !isWeChat">
          <div>
            {{ $t('Contact Gloda') }}
          </div>
          <div class="my-20 h-148 w-220px flex flex-col items-center justify-center gap-12 rd-8 bg-[--color-fill-1]">
            <img :src="email" class="w-32">
            gloda2024@gmail.com
          </div>
          <BtnsCopy title="Copy email" icon="" text="gloda2024@gmail.com">
            <a-button type="outline" shape="round" size="small">
              {{ $t('Copy email') }}
            </a-button>
          </BtnsCopy>
        </template>
        <div v-else>
          <div flex items-center justify-center>
            <img :src="isWeChat ? weChat : contact" class="m-12 w-196">
          </div>
          <div>
            {{ $t(dec) }}
          </div>
        </div>
      </div>
    </template>
  </a-trigger>
</template>

<style>

</style>
