<script setup lang="tsx">
import { MenuItem, SubMenu } from '@arco-design/web-vue'

interface IMenuItem {
  title: string
  path: string
  icon?: string
  children?: IMenuItem[]
}

const { t } = useI18n()
const localePath = useLocalePath()
const route = useRoute()
const menuStore = useMenu()
const userStore = useUser()
const { width } = useWindowSize()
// const shortNameKey = useI18nKeys('shortName')

const COLLAPSED_WIDTH = 80
const UNCOLLAPSED_WIDTH = 208

onMounted(() => {
  if (width.value < 1568) {
    menuStore.value.collapsed = true
  }
})

watch(width, () => {
  if (width.value < 1568) {
    menuStore.value.collapsed = true
  }
})
watch(() => menuStore.value.collapsed, (val) => {
  menuStore.value.width = val ? COLLAPSED_WIDTH : UNCOLLAPSED_WIDTH
})

const MENUS = computed<IMenuItem[]>(() => [
  {
    title: t('选品'),
    path: 'products',
    icon: 'icon-xuanpinku',
    children: [
      { title: t('选品库'), path: localePath('/analysis/products') },
      { title: t('热销商品榜'), path: localePath('/analysis/products/hotSale') },
      { title: t('达人热推榜'), path: localePath('/analysis/products/hotPush') },
    ],
  },
  {
    title: t('小店'),
    path: 'shop',
    icon: 'icon-xiaodian',
    children: [
      { title: t('小店库'), path: localePath('/analysis/shop') },
      { title: t('热销小店榜'), path: localePath('/analysis/shop/hotSale') },
      { title: t('全托管店榜'), path: localePath('/analysis/shop/hosting') },
      { title: t('达人合作榜'), path: localePath('/analysis/shop/bloggerInteract') },
    ],
  },
  {
    title: t('达人'),
    path: 'blogger',
    icon: 'icon-daren',
    children: [
      { title: t('达人库'), path: localePath('/analysis/blogger') },
      { title: t('达人涨粉榜'), path: localePath('/analysis/blogger/increaseFans') },
      { title: t('达人带货榜'), path: localePath('/analysis/blogger/ecommerce') },
    ],
  },
  {
    title: t('视频'),
    path: 'video',
    icon: 'icon-shipin',
    children: [
      { title: t('视频库'), path: localePath('/analysis/video') },
      // { title: t('热门视频榜'), path: localePath('/analysis/video/hot') },
      // { title: t('带货视频榜'), path: localePath('/analysis/video/ecommerce') },
    ],
  },
  {
    title: t('直播'),
    path: 'live',
    icon: 'icon-zhibo',
    children: [
      { title: t('直播库'), path: localePath('/analysis/live') },
      { title: t('直播带货榜'), path: localePath('/analysis/live/ecommerce') },
    ],
  },
  {
    title: t('我的收藏'),
    path: 'favourites',
    icon: 'icon-shoucang1',
    children: [
      { title: t('商品收藏'), path: localePath('/favourites/product') },
      { title: t('小店收藏'), path: localePath('/favourites/shop') },
      { title: t('达人收藏'), path: localePath('/favourites/blogger') },
      { title: t('视频收藏'), path: localePath('/favourites/video') },
      { title: t('直播收藏'), path: localePath('/favourites/live') },
    ],
  },
  {
    title: t('个人中心'),
    path: 'user',
    icon: 'icon-gerenzhongxin',
    children: [
      { title: t('个人信息'), path: localePath('/user/info') },
      // { title: t('成员管理'), path: localePath('/user/member') },
      // { title: t('我的订单'), path: localePath('/user/order') },
    ],
  },
])
const filterMenus = computed(() => {
  return MENUS.value.filter((menu) => {
    if (menu.path === 'user') {
      return userStore.value?.info?.id
    }
    return true
  })
})

function findParentPath(targetPath: string, data = filterMenus.value): string {
  for (const node of data) {
    if (node.children) {
      // Check if any child has the targetPath
      for (const child of node.children) {
        if (child.path === targetPath) {
          return node.path // Return the parent's path
        }
      }

      // Recursively search in the children
      const parentPath = findParentPath(targetPath, node.children)
      if (parentPath) {
        return parentPath
      }
    }
  }

  return ''
}

function renderMenu() {
  const render = (menu = filterMenus.value) => {
    return menu.map((menuItem) => {
      if (menuItem.children && menuItem.children.length) {
        return (
          <SubMenu key={menuItem.path} title={menuItem.title}>
            {{
              default: () => render(menuItem.children),
              icon: menuItem.icon ? () => <i class={menuItem.icon} /> : null,
            }}
          </SubMenu>
        )
      }
      return <MenuItem key={menuItem.path} title={menuItem.title}>{ menuItem.title }</MenuItem>
    })
  }
  return render()
}

const selectedKeys = ref<string[]>()
const openKeys = ref<string[]>()
watch(() => route.path, () => {
  const cleanedPath = route.path?.replace(/\/detail.*/, '')
  selectedKeys.value = [cleanedPath]
  openKeys.value = [findParentPath(cleanedPath)]
}, { immediate: true })

function onClickMenuItem(key: string) {
  navigateTo(key)
}

function onClickSubMenu(key: string, opens: string[]) {
  if (opens.length === 0) {
    openKeys.value = []
    return
  }
  openKeys.value = [key]
}

// function handleGlobalSetting() {
//   const { close } = Modal.open({
//     title: t('全局设置'),
//     width: 840,
//     footer: false,
//     content: () => h(GlobalSetting, { closeModal: close }),
//   })
// }
</script>

<template>
  <div>
    <a-menu
      v-model:selected-keys="selectedKeys"
      v-model:collapsed="menuStore.collapsed"
      :open-keys="openKeys"
      class="h-full"
      :style="{ 'width': `${UNCOLLAPSED_WIDTH}px`, '--border-radius-small': '4px' }"
      auto-open-selected show-collapse-button
      :level-indent="26" :collapsed-width="COLLAPSED_WIDTH"
      :trigger-props="{
        'arrow-class': 'hidden',
        'mouse-leave-delay': 200,
        'popup-offset': 14,
      }"
      @sub-menu-click="onClickSubMenu"
      @menu-item-click="onClickMenuItem"
    >
      <!-- 暂不支持 -->
      <!-- <div>
        <div
          class="flex items-center py-9 text-[--color-text-2] font-500"
          :class="{ 'justify-center': menuStore.collapsed, 'justify-between': !menuStore.collapsed }"
        >
          <a-space v-if="!menuStore.collapsed && userStore.regions.length" :size="10">
            <img class="w-16" :src="userStore.regions[0].regionFlag">
            <span class="ellipsis">{{ userStore.regions[0][shortNameKey] }}</span>
          </a-space>
          <g-svg class="icon-btn" name="global-setting" :width="16" :height="16" @click="handleGlobalSetting" />
        </div>
        <a-divider :margin="8" />
      </div> -->

      <component :is="renderMenu" />

      <template #collapse-icon>
        <div class="h-44 w-12 flex items-center rd-r-8 bg-white">
          <i
            class="icon-xiangzuo text-12 text-[--color-text-4] transition-transform-300"
            :class="menuStore.collapsed ? 'rotate-180' : ''"
          />
        </div>
      </template>
    </a-menu>
  </div>
</template>

<style scoped lang="less">
:deep(.arco-menu .arco-menu-inner) {
  @apply "p-20";
}
:deep(.arco-menu .arco-menu-item) {
  @apply "text-[--color-text-3]";
}
:deep(.arco-menu .arco-menu-item:hover) {
  @apply "text-[--color-text-3] bg-[--color-fill-6]";
}
:deep(.arco-menu .arco-menu-item.arco-menu-selected) {
  @apply "text-[--color-brand-6]! bg-[#F8F6FD]!";
}
:deep(.arco-menu-vertical .arco-menu-item), :deep(.arco-menu-vertical .arco-menu-group-title), :deep(.arco-menu-vertical .arco-menu-pop-header), :deep(.arco-menu-vertical .arco-menu-inline-header) {
  @apply "p-0";
}
:deep(.arco-menu .arco-menu-inline-header .arco-menu-title) {
  @apply "font-600";
}
:deep(.arco-menu .arco-menu-icon) {
  @apply "text-16 mr-10 text-[--color-text-2]";
}
:deep(.arco-menu-light .arco-menu-inline-header:hover .arco-menu-icon) {
  @apply "text-[--color-text-2]";
}
:deep(.arco-menu-light .arco-menu-inline .arco-menu-selected .arco-menu-icon) {
  @apply "text-[--color-brand-6]";
}
:deep(.arco-menu .arco-menu-pop-header) {
  @apply "justify-center";
}
:deep(.arco-menu .arco-menu-pop-header:hover .arco-menu-icon) {
  @apply "text-[--color-text-2]";
}
:deep(.arco-menu .arco-menu-pop-header.arco-menu-selected .arco-menu-icon) {
  @apply "text-[--color-brand-6]";
}
:deep(.arco-menu .arco-menu-pop-header .arco-menu-icon) {
  @apply "mr-0";
}
:deep(.arco-menu .arco-menu-pop-header .arco-menu-title) {
  @apply "w-0";
}
:deep(.arco-menu .arco-menu-collapse-button) {
  @apply "top-50% right--12 w-12 h-44 translate-y--6 bg-transparent";
}
</style>

<style lang="less">
.arco-menu-pop-trigger {
  // @apply "!left-74";

  .arco-trigger-menu {
    @apply "p-8";
  }

  .arco-trigger-menu-item {
    @apply "w-140 pl-16 pr-0 text-[--color-text-3]";
  }

  .arco-trigger-menu-selected {
    @apply "text-[--color-brand-6]! bg-[#F8F6FD]!";
  }

  .arco-trigger-menu-item:hover, .arco-trigger-menu-pop-header:hover {
    @apply "text-[--color-text-3] bg-[--color-fill-6]";
  }
}
</style>
