<script setup lang="ts">
const router = useRouter()
const route = useRoute()
const searchHistory = useLocalStorage<string[]>('search-history', [])
const localePath = useLocalePath()

const keyword = ref('')
const historyVisible = ref(false)

const placeholder = computed(() => {
  switch (route.path) {
    case localePath('/analysis/products'):
      return '请输入商品名称、小店名称关键词或TK商品链接搜索'
    case localePath('/analysis/shop'):
      return '请输入小店名称关键词搜索'
    case localePath('/analysis/blogger'):
      return '请输入达人昵称或达人ID搜索'
    case localePath('/analysis/video'):
      return '请输入视频标题、达人昵称、商品标题关键词或达人ID'
    case localePath('/analysis/live'):
      return '请输入直播间名称、达人昵称关键词或达人ID'
  }
  return ''
})

function onSearch() {
  if (keyword.value) {
    if (searchHistory.value.includes(keyword.value)) {
      searchHistory.value.splice(searchHistory.value.indexOf(keyword.value), 1)
    }
    searchHistory.value.unshift(keyword.value)
    if (searchHistory.value.length > 5)
      searchHistory.value.pop()
  }
  historyVisible.value = false

  router.replace({ query: { keyword: keyword.value } })
}

const { open: translate } = useSearchTranslate()
function handleTranslate() {
  translate({ text: keyword.value }).then((text) => {
    keyword.value = text
  })
}

watch(() => route.query.keyword, (val) => {
  keyword.value = val as string
}, { immediate: true })

function handleSelectKeyword(his: string) {
  keyword.value = his
  historyVisible.value = false
}
</script>

<template>
  <div class="h-44 w-800">
    <a-trigger
      :popup-visible="historyVisible"
      :popup-translate="[0, 12]"
      auto-fit-popup-width
      content-class="bg-white"
      update-at-scroll
      prevent-focus
    >
      <a-input-search
        v-model="keyword"
        class="h-full overflow-hidden rd-24 pr-0"
        :button-text="$t('搜索')"
        :placeholder="$t(placeholder)" allow-clear search-button
        :button-props="{ class: 'h-full w-108 text-16 font-600' }"
        @clear="onSearch"
        @search="onSearch"
        @press-enter="onSearch"
        @focus="searchHistory.length > 0 && (historyVisible = true)"
        @blur="historyVisible = false"
      >
        <template #suffix>
          <i
            class="icon-zhongyingwenqiehuan text-18 text-[--color-text-3] icon-btn"
            @click="handleTranslate"
          />
        </template>
      </a-input-search>
      <template #content>
        <div class="w-full overflow-hidden rd-8" style="box-shadow: 0px 4px 10px 0px #0000001A;">
          <div class="h-46 flex items-center justify-between px-24">
            <span class="text-[--color-text-3]">
              {{ $t('最近搜过') }}
            </span>
            <a-button
              v-if="searchHistory.length > 0"
              type="text"
              style="padding: 0;"
              @click="searchHistory = []; historyVisible = false"
            >
              {{ $t('清空') }}
            </a-button>
          </div>
          <div
            v-for="(his, i) in searchHistory" :key="i"
            class="search-history-item h-38 flex cursor-pointer justify-between gap-16 px-24 text-[--color-text-2] line-height-38 hover:bg-[--color-brand-1]"
            @click="handleSelectKeyword(his)"
          >
            <div w-0 flex-1 ellipsis>
              {{ his }}
            </div>
            <i class="icon-shanchu hidden cursor-pointer text-12" @click.stop="searchHistory.splice(i, 1)" />
          </div>
        </div>
      </template>
    </a-trigger>
  </div>
</template>

<style scoped>
:deep(.arco-input-wrapper) {
  @apply 'b-2 b-solid b-[--color-brand-6] b-r-0 pl-24 rd-l-24! bg-white';
}

:deep(.arco-input::-webkit-input-placeholder) {
  @apply 'text-[--color-text-4]';
}

.search-history-item:hover > i {
  display: block;
}
</style>
